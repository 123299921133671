import '../styles/MultiRangeSlider.css'

import { useState } from 'react'

function disableFocus(setFocus)
{
    setFocus(0);
}

function selectRange(value, levelMin, setLevelMin, levelMax, setLevelMax, focus, setFocus)
{
    if(focus === 0)//Permier Click
    {
        //Focus sur le plus proche
        if (levelMin !== levelMax)
        {
            //Focus sur le plus proche
            if (Math.abs(levelMin - value) > Math.abs(levelMax - value)) 
            {
                setLevelMax(value);
                setFocus(1);
            }
            else
            {
                setLevelMin(value);
                setFocus(2);
            }
        }
        else//Si les deux points sont superposée (levelMin === levelMax)
        {
            if ((levelMin - value) > 0)
            {
                setLevelMin(value);
                setFocus(2);                
            }
            else
            {
                setLevelMax(value);
                setFocus(1);
            }            
        }
    }
    else//Click Maintenue
    {
        if(focus === 1)//Focus sur LevelMax (1)
        {
            if (Number(value) < Number(levelMin))
            {
                setLevelMin(value);
                setLevelMax(levelMin);
                setFocus(2);
            }
            else
            {
                setLevelMax(value);
            }            
        }
        else//Focus sur LevelMin (2)
        {
            if (Number(value) > Number(levelMax))
            {
                setLevelMax(value);
                setLevelMin(levelMax);
                setFocus(1);
            }
            else
            {
                setLevelMin(value);
            }
        }
    }
}

function MultiRangeSlider({ levelMin, levelMax, setLevelMin, setLevelMax }) {
    const [focus, setFocus] = useState(0)

    return (
        <div className='MultiRangeSlider' id="slider-distance">
            <div className="slider_MultiRangeSlider">
                <div style={{ left: `${(levelMin/2.3)*91/100}%` }} className="thumb_MultiRangeSlider"></div>
                <div style={{ left: `${(levelMax/2.3)*91/100}%` }} className="thumb_MultiRangeSlider"></div>
                <div style={{ left: `${Math.min(levelMin, levelMax)/2.3}%`, width: `${Math.abs(levelMax - levelMin)/2.3}%` }} className="range_MultiRangeSlider"></div>
                <input min="0" max ="230" type="range" onInput={(e) => selectRange(e.target.value, levelMin, setLevelMin, levelMax, setLevelMax, focus, setFocus)} onMouseUp={(e) => disableFocus(setFocus)}/>
            </div>
        </div>
    )
}

export default MultiRangeSlider