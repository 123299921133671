import '../styles/ItemsSelected.css'

import ItemSelected from './ItemSelected'

function ItemsSelected({ itemsSelected, jsonItems }) {

    return (
        <div className='ItemsSelected'>
            <ItemSelected itemSelected={itemsSelected} id={0} jsonItems={jsonItems}/>
            <ItemSelected itemSelected={itemsSelected} id={1} jsonItems={jsonItems}/>
            <ItemSelected itemSelected={itemsSelected} id={2} jsonItems={jsonItems}/>
            <ItemSelected itemSelected={itemsSelected} id={3} jsonItems={jsonItems}/>
            <ItemSelected itemSelected={itemsSelected} id={4} jsonItems={jsonItems}/>
            <ItemSelected itemSelected={itemsSelected} id={5} jsonItems={jsonItems}/>
            <ItemSelected itemSelected={itemsSelected} id={6} jsonItems={jsonItems}/>
            <ItemSelected itemSelected={itemsSelected} id={7} jsonItems={jsonItems}/>
            <ItemSelected itemSelected={itemsSelected} id={8} jsonItems={jsonItems}/>
            <ItemSelected itemSelected={itemsSelected} id={9} jsonItems={jsonItems}/>
            <ItemSelected itemSelected={itemsSelected} id={10} jsonItems={jsonItems}/>
            <ItemSelected itemSelected={itemsSelected} id={11} jsonItems={jsonItems}/>
            <ItemSelected itemSelected={itemsSelected} id={12} jsonItems={jsonItems}/>
            <ItemSelected itemSelected={itemsSelected} id={13} jsonItems={jsonItems}/>
        </div>
    )
}

export default ItemsSelected