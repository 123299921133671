import '../styles/Footer.css'

function Footer() {
    return (
        <footer className='Footer'>
            <span>WAKFU MMORPG : © 2012-2022 Ankama Studio. Tous droits réservés.</span>
            <span>"WakfuBuilder" est un site non-officiel sans aucun lien avec Ankama.</span>
        </footer>
        )
}

export default Footer