import React from 'react'

import { useState } from 'react'

import '../styles/SelectRarity.css'

import { RARITY } from './Settings'

//Gestion input button
//Si le rarityId "id" est inclus dans "filter.rarity[]" alors l'exclu 
//Si non l'inclus
function inverseInput(filter, rarityId, state, resetState) {
    var imageRarity = document.getElementById(rarityId).className;
    rarityId = Number(rarityId);
    if (imageRarity === 'imageRarityDeselected')
    {
        filter.rarity.push(rarityId);
    }
    else
    {
        for (var type in filter.rarity)
        {
            if (filter.rarity[type] === rarityId)
            {
                filter.rarity[type] = filter.rarity[filter.rarity.length-1]
                filter.rarity.pop();
            }
        }
    }
    if (state === true)
    {
        return (resetState(false))
    }
    else
    {
        return (resetState(true))
    }
}
//Affiche les buttons
//Si le rarity "id" est inclus dans "filter.rarity[]" affiche un button de className 'Selected' si non 'Deselected'
function imageRarity(filter, state, resetState, id)
{   
    for (var rarity in filter.rarity)
    {
        //console.log(`rarity selected`, rarity)
        if (filter.rarity[rarity] === id)
        {
            return(
            <div className='RaritySelection' key={id+'d_s'}>
                <button className='RaritySelected' key={id+'b_s'} onClick={() => inverseInput(filter, id, state, resetState)}>
                    <img className='imageRaritySelected' id={id} key={id+'i_s'} src={`./assets/icones/${id}.png`} alt={`Icones du type ${RARITY[id]}`}/>
                </button>
                <span className="tooltiptext">{RARITY[id]}</span>
            </div>
            )
        
        }
    }
    //console.log(`rarity deselected, id :`, id)
    return(
    <div className='RaritySelection' key={id+'d_d'}>
        <button className='RarityDeselected' key={id+'b_d'} onClick={() => inverseInput(filter, id, state, resetState)}>
            <img className='imageRarityDeselected' id={id} key={id+'i_d'} src={`../assets/icones/${id}.png`} alt={`Icones du type ${RARITY[id]}`}/>
        </button>
        <span className="tooltiptext">{RARITY[id]}</span>
    </div>
    )
}

function SelectRarity({ copyFilter }) {
    const [state, resetState] = useState(true) //Permet d'accutualiser le components à chaque input
    return (
        <div className='SelectRarity'>
            {imageRarity(copyFilter, state, resetState, 1)}
            {imageRarity(copyFilter, state, resetState, 2)}
            {imageRarity(copyFilter, state, resetState, 3)}
            {imageRarity(copyFilter, state, resetState, 4)}
            {imageRarity(copyFilter, state, resetState, 5)}
            {imageRarity(copyFilter, state, resetState, 6)}
            {imageRarity(copyFilter, state, resetState, 7)}
        </div>
        )
}

export default SelectRarity