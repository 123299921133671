import React from 'react'

import { useState } from 'react'

import '../styles/SelectSort.css'

//Gestion input button
//Si le rarityId "id" est inclus dans "filter.rarity[]" alors l'exclu 
//Si non l'inclus
function inverseInput(filter, state, resetState) {
    var sortLevel = document.getElementById('Level').className;
    if (sortLevel === 'Selected')
    {
        filter.levelSort = 0;
    }
    else
    {
        filter.levelSort = 1;
    }
    if (state === true)
    {
        return (resetState(false))
    }
    else
    {
        return (resetState(true))
    }
}
//Affiche les buttons
//Si le rarity "id" est inclus dans "filter.rarity[]" affiche un button de className 'Selected' si non 'Deselected'
function imageRarity(filter, state, resetState)
{   
    if (filter.levelSort === 1)
    {
        return(
        <div className='SortSelection'>
            <button className='SortSelected' onClick={() => inverseInput(filter, state, resetState)}>
                <span className='Selected' id='Level'>Level</span>
                <div className="arrow arrow_down"></div>
            </button>
            <span className="tooltiptext">Décroissant</span>
        </div>
        )
    }
    return(
    <div className='SortSelection'>
        <button className='SortDeselected' onClick={() => inverseInput(filter, state, resetState)}>
            <span className='Deselected' id='Level'>Level</span>
            <div className="arrow arrow_up"></div>
        </button>
        <span className="tooltiptext">Croissant</span>
    </div>
    )
}

function SelectSort({ copyFilter }) {
    const [state, resetState] = useState(true) //Permet d'accutualiser le components à chaque input
    return (
        <div className='SelectSort'>
            {imageRarity(copyFilter, state, resetState)}
        </div>
        )
}

export default SelectSort