function PhpLogin (login, password, setName, setTestLogin)
{
    var requete = new XMLHttpRequest();
    requete.onload = function() 
    {
        var response = this.responseText;
        response = response.slice(0, response.length - 4);
        if (response !== '-1')
        {
            setName(response);
            document.cookie = 'user=' + response + '; path =/;samesite=strict; secure';
        }
        else
        {
            setTestLogin(-1)
        }
    };
    requete.open("GET", "login.php?Login=" + login + "&Password=" + password, true);   
    requete.send();
}
export default PhpLogin
