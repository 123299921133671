import '../styles/Header.css'
import PhpLogin from './PhpLogin';
import PhpRegistration from './PhpRegistration';
import ItemsSelected from './ItemsSelected'

import { useState } from 'react'

function openDialogLogin()
{
    const dialog = document.getElementById('Dialog_Login');
    dialog.setAttribute('open', true);
}

function openDialogRegistration()
{
    const dialog = document.getElementById('Dialog_Registration');
    dialog.setAttribute('open', true);
}

function closeDialogLogin()
{
    const dialog = document.getElementById('Dialog_Login');
    dialog.removeAttribute('open');
}

function closeDialogRegistration()
{
    const dialog = document.getElementById('Dialog_Registration');
    dialog.removeAttribute('open');
}

function swapDialog(dialog)
{
    if(dialog === "login")
    {
        closeDialogRegistration();
        openDialogLogin();        
    }
    if (dialog === "registration")
    {
        closeDialogLogin();
        openDialogRegistration();
    }
}

function login(setName, setTestLogin)
{
    const login = document.getElementById('login').value;
    const password = document.getElementById('login_password').value;
    PhpLogin(login, password, setName, setTestLogin);
}

function registration(setName, setTestRegistration)
{
    const name = document.getElementById('registration_name').value;
    const email = document.getElementById('registration_email').value;
    const password = document.getElementById('registration_password').value;

    var test = 1;
    //const isEmailValid = address => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(address);
    if (!(email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)))
    {
        test = 0;
        console.log('email invalide');
    }
    if(!(name.match(/^([0-9a-zA-Z]){6,20}$/)))
    {
        test = 0;
        console.log('name invalide');
    }
    if(!(password.length >= 6 && password.length <= 20))
    {
        test = 0;
        console.log('password invalide');
    }
    console.log('test registration : ', test);
    if (test === 1)
    {
        console.log('PhpRegistration()');
        if (!(PhpRegistration(name, email, password, setName, setTestRegistration) === 1))
        {
            console.log('registration invalide');
        }
    }
}

function logout(setName)
{
    document.cookie = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    setName('');
}

function saveBuild(itemsSelected, name)
{
    var requete = new XMLHttpRequest();
    requete.onload = function() 
    {
    };
    requete.open("GET", "save_build.php?User=" + name + "&FirstWeapon=" + itemsSelected[0] + "&SecondWeapon=" + itemsSelected[1] + "&LeftHand=" + itemsSelected[2] + "&RightHand=" + itemsSelected[3] + "&Legs=" + itemsSelected[4] + "&Neck=" + itemsSelected[5] + "&Back=" + itemsSelected[6] + "&Belt=" + itemsSelected[7] + "&Head=" + itemsSelected[8] + "&Chest=" + itemsSelected[9] + "&Shoulders=" + itemsSelected[10] + "&Accessory=" + itemsSelected[11] + "&Pet=" + itemsSelected[12] + "&Costume=" + itemsSelected[13], true);
    requete.send();
}

function loadBuild(stateApp, builds, name)
{
    var requete = new XMLHttpRequest();
    requete.onload = function() 
    {
        var response = this.responseText;
        response = response.slice(0, response.length - 4);
        if (response !== '[]')
        {
            builds[0]=(JSON.parse(response)[0]);
            builds.push(JSON.parse(response)[0]);
            stateApp.update();
        }        
    };
    requete.open("GET", "get_build.php?User=" + name, true);
    requete.send();
}

function displayItemsSelected(itemsSelected, jsonItems)
{
    return (
        <ItemsSelected itemsSelected={itemsSelected} jsonItems={jsonItems}/>
    )
}

function Header( { stateApp, builds, name, setName, jsonItems } ) {
    const [testLogin, setTestLogin] = useState(0)
    const [testRegistration, setTestRegistration] = useState(0)

    if(name === '' && document.cookie.slice(0,5) === 'user=')
    {
        setName(document.cookie.slice(5));
        return (
            <div className='Header'>
            </div>
        )
    }

    if (name === '')
    {
        return (
            <header className='HeaderAndDialog'>
                <div className='Header'>
                    <button onClick={() => swapDialog("login")}>
                        <span>Connexion</span>
                    </button>
                    <ItemsSelected itemsSelected={builds[0]} jsonItems={jsonItems}/>
                </div>
                <dialog id='Dialog_Login'>
                    <div className='Dialog'>
                        <div className='Close_Dialog_Div_Login'>
                            <button className='Close_Button' onClick={() => closeDialogLogin()}>
                                <div className='Close'></div>
                            </button>   
                        </div>
                        <form method='post' action=''>
                            <p>
                                <label for="login">Identifiant</label>
                                <br />
                                <input type="text" name="login" id="login" placeholder="Nom d'utilisateur ou email" size="32" maxlength="32" />
                            </p>
                            <p>
                                <label for="login_password">Mot de pass</label>
                                <br />
                                <input type="password" name="login_password" id="login_password" size="32" maxlength="32" />
                            </p>
                        </form>
                        <span>{testLogin === 0 ? '' : 'Veuillez vérifier vos identifiants'}</span>
                        <button className='Dialog_Button' onClick={() => login(setName, setTestLogin)}>
                            <span>Se connecter</span>
                        </button>
                        <button className='Dialog_Button' id='Registration_Button' onClick={() => swapDialog("registration")}>
                            <span>Créer un compte</span>
                        </button>
                    </div>
                </dialog>
                <dialog id='Dialog_Registration'>
                    <div className='Dialog'>
                        <div className='Close_Dialog_Div_Registration'>
                            <button className='Close_Button' onClick={() => swapDialog("login")}>
                                <div className='Arrow'></div>
                            </button>
                            <button className='Close_Button' onClick={() => closeDialogRegistration()}>
                                <div className='Close'></div>
                            </button>                            
                        </div>
                        <form method='post' action=''>
                            <p>
                                <label for="registration_name">Nom d'utilisateur</label>
                                <br />
                                <input type="text" name="registration_name" id="registration_name" size="32" maxlength="32" />
                            </p>
                            <p>
                                <label for="registration_email">Email</label>
                                <br />
                                <input type="email" name="registration_email" id="registration_email" size="32" maxlength="32" />
                            </p>
                            <p>
                                <label for="registration_password">Mot de pass</label>
                                <br />
                                <input type="password" name="registration_password" id="registration_password" size="32" maxlength="32" />
                            </p>
                        </form>
                        <span>{testRegistration === 0 ? '' : 'Identifiant ou adresse email déjà utilisée'}</span>
                        <button className='Dialog_Button' onClick={() => registration(setName, setTestRegistration)}>
                            <span>Inscription</span>
                        </button>                        
                    </div>
                </dialog>
            </header>
        )
    }
    else
    {
        return (
            <header className='HeaderAndDialog'>
                <div className='Header'>
                    <span>{name}</span>
                    <button id='Input_LogOut' onClick={() => logout(setName)}>
                        <span>Déconnexion</span>
                    </button>
                    <button onClick={() => saveBuild(builds[0], name)}>
                        <span>Enregistrer builds</span>
                    </button>
                    <button onClick={() => loadBuild(stateApp, builds, name)}>
                        <span>Charger builds</span>
                    </button>
                    <div className='Builds'>
                        {displayItemsSelected(builds[stateApp.buildSelected], jsonItems)}
                    </div>                                      
                </div>                
            </header>
        )
    }
}

export default Header

