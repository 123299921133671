import '../styles/StatLine.css'

import JSON_Actions from '../datas/actions.json'
import JSON_States from '../datas/states.json'

function findReplace(str, find, replace)
{   
    var index = str.indexOf(find)
    if (index >= 0)
    {
        str = str.replace(find, replace)
    }
    return (str)
}

function parsingRandomElem(str, nbrElement)
{   
    if (str.indexOf('Maîtrise'))
    {
        str = '[#1] Maîtrise sur [#2] élément{[>2]?s:} aléatoire{[>2]?s:}';
    }
    else
    {
        str = '[#1] Résistance sur [#2] élément{[>2]?s:} aléatoire{[>2]?s:}';
    }
    if (nbrElement > 1)
    {
        str = findReplace(str, '{[>2]?s:}', 's');
        str = findReplace(str, '{[>2]?s:}', 's');
    }
    else
    {
        str = findReplace(str, '{[>2]?s:}', '');
        str = findReplace(str, '{[>2]?s:}', '');
    }
    str = findReplace(str, '[#2]', nbrElement);
    return (str)
}

function parsingStat(str, valueEffect)
{
    if(str === '[#2] Niv. aux sorts [#1]')
    {
        switch (valueEffect[0]) 
        {
            case 1:
                str = findReplace(str, '[#1]', 'Feu');
                break;
            case 2:
                str = findReplace(str, '[#1]', 'Eau');
                break;
            case 3:
                str = findReplace(str, '[#1]', 'Terre');
                break;
            case 4:
                str = findReplace(str, '[#1]', 'Air');
                break;
            default:
        }
        str = findReplace(str, '[#2]', valueEffect[2]);
    }
    if (str === '{[~3]?[#1] Maîtrise [#3]:[#1] Maîtrise sur [#2] élément{[>2]?s:} aléatoire{[>2]?s:}}' ||
        str === '{[~3]?[#1] Résistance [#3]:[#1] Résistance sur [#2] élément{[>2]?s:} aléatoire{[>2]?s:}}')
    {
        str = parsingRandomElem(str, valueEffect[2]);
    }
    str = findReplace(str, '[#1]', valueEffect[0]);
    str = findReplace(str, '[el1]', 'Feu');
    str = findReplace(str, '[el2]', 'Eau');
    str = findReplace(str, '[el3]', 'Terre');
    str = findReplace(str, '[el4]', 'Air');
    if (str.indexOf('[') >= 0)
    {
        console.log('StatLine.js | Effect non géré : ', str)
    }
    return (str)
}

function findEffect(idnbr, effect)
{
    let id = idnbr.toString();
    var param = 0;
    if(id === '21') //perte de PV
    {
        return("-[#1] PV");
    }
    if(id === '304') //item avec effet a appliquer 
    {
        for (var index in JSON_States)
        {
            if (JSON_States[index].definition.id === effect.definition.params[0])
            {
                return (JSON_States[index].title.fr)
            }
        }
    }
    if(id === '1020') //item "Makabrano Zer"
    {
        return("Renvoie 10% des dégâts ");
    }
    if(id === '39') //gain de caractéristique passer en paramètre
    {
        param = effect.definition.params[4];
        if (param === 120)
        {
            return("[#1]% Armure donnée");
        }   
        if (param === 121)
        {
            return("[#1]% Armure reçue");
        }  
    }
    if(id === '40') //perte de caractéristique passer en paramètre
    {
        param = effect.definition.params[4];
        if (param === 120)
        {
            return("-[#1]% Armure donnée");
        }   
        if (param === 121)
        {
            return("-[#1]% Armure reçue");
        }  
    }
    if(id === '2001') //%de récolte pour métier
    {
        param = effect.definition.params[2];
        if (param === 64)
        {
            return("[#1]% Quantité Récolte en Paysan");
        } 
        if (param === 73)
        {
            return("[#1]% Quantité Récolte en Forestier");
        }  
        if (param === 72)
        {
            return("[#1]% Quantité Récolte en Herboriste");
        }  
        if (param === 71)
        {
            return("[#1]% Quantité Récolte en Mineur");
        }  
        if (param === 75)
        {
            return("[#1]% Quantité Récolte en Trappeur");
        }  
        if (param === 74)
        {
            return("[#1]% Quantité Récolte en Pêcheur");
        }  
    }
    if(id === '234') //Art du Barda"
    {
        return("[#1] Art du Barda (disparaît bientôt !)");
    }
    if(id === '400') //NullEffect : Effet Vide
    {
        return("Erreur NullEffect");
    }
    for (var action in JSON_Actions)
    {
        if(JSON_Actions[action].definition.id === idnbr)
        {
            if (action === 1)
            {
                return("Erreur findEffect");
            }
            try {
                if(JSON_Actions[action].description.fr)
                {
                    return(JSON_Actions[action].description.fr);
                }
                else 
                {
                    return("Erreur findEffect");
                }
            } catch (error) {
                console.log('StartLine.js | Erreur effect sans description non géré id: ',idnbr);
            }
        }        
    }
    return("Erreur inconue");
}

function valueOfEffect(item, actionId, jsonItems)
{
    if (item >= 0)
    {
        for(var effect in jsonItems[item].definition.equipEffects)
        {
            if (jsonItems[item].definition.equipEffects[effect].effect.definition.actionId === actionId)
            {
                return (jsonItems[item].definition.equipEffects[effect].effect.definition.params[0]);
            }
        }
    }
}

function compareStat(valueEffectA, nbrItemB, actionId, jsonItems)
{
    var valueBefore = valueOfEffect(nbrItemB, actionId, jsonItems)
    return (valueEffectA - valueBefore);
}

function compareType(itemsSelected, typeId, jsonItems)
{
    for (var item in itemsSelected)
    {
        if (itemsSelected[item] >= 0)
        {
            if (jsonItems[itemsSelected[item]].definition.item.baseParameters.itemTypeId === typeId)
            {
                return (itemsSelected[item])
            }
        }
    }
    return (-1);
}

function printDiffStat(nbrItemA, itemsSelected, valueEffectA, actionId, jsonItems)
{
    var nbrItemB = compareType(itemsSelected, jsonItems[nbrItemA].definition.item.baseParameters.itemTypeId, jsonItems)
    
    if (nbrItemB >= 0)
    {
        var diffValue = compareStat(valueEffectA, nbrItemB, actionId, jsonItems);
        if (diffValue > 0)
        {
            return(
                <span className='Positive'>(+{diffValue})</span>
            )
        }
        else if (diffValue < 0)
        {
            return(
                <span className='Negative'>({diffValue})</span>
            )
        }  
    } 
}
    
function StatLine({ nbrItem, effect, stats, itemsSelected, jsonItems}) {
    return (
    <div className='StatLine'>
        <span>{parsingStat(findEffect(effect.definition.actionId, effect), effect.definition.params)}</span>
        {printDiffStat(nbrItem, itemsSelected, effect.definition.params[0], effect.definition.actionId, jsonItems)}
    </div>
    )
}

export default StatLine