import '../styles/ItemSelected.css'

function ItemSelected({ itemSelected, id, jsonItems}) {
    if (id === 1 && itemSelected[0] >= 0)
    {
        var typeId = jsonItems[itemSelected[0]].definition.item.baseParameters.itemTypeId;
        if (typeId === 101 || typeId === 111 || typeId === 114 || typeId === 117 || typeId === 223 || typeId === 253)
        {
            try {
                return (
                    <div className='ItemSelected' id={`ItemSelected_${id}`}>
                        <img style={{ opacity: '0.5' }} className='ImageItem_ItemSelected' src={`../assets/images_items/${jsonItems[itemSelected[0]].definition.item.graphicParameters}.png`} alt={'L\'item : ' + jsonItems[itemSelected[0]].title}/>
                    </div> 
                    )
                } catch (error) {
                console.log(`ItemSelected.js | image manquante pour ItemSelected ${itemSelected[0]}`)
                console.log(error)
            }
        }
    }

    if (itemSelected[id] >= 0)
    {
        try {
            return (
                <div className='ItemSelected' id={`ItemSelected_${id}`}>
                    <img className='ImageItem_ItemSelected' src={`../assets/images_items/${jsonItems[itemSelected[id]].definition.item.graphicParameters}.png`} alt={'L\'item : ' + jsonItems[itemSelected[id]].title}/>
                </div> 
                )
            } catch (error) {
            console.log(`ItemSelected.js | image manquante pour ItemSelected ${itemSelected[id]}`)
            console.log(error)
            return (
            <div className='ItemSelected' id={`ItemSelected_${id}`}>
                <img className='ImageItem_ItemSelected' alt={'L\'item : ' + jsonItems[itemSelected[id]].title}/>
            </div> 
            )
        }
    }
    else
    {
        try {
            return (
                <div className='ItemSelected' id={`ItemSelected_${id}`}>
                    <img className='ImageType_ItemSelected' src={`../assets/defaut_type_item_selected/${id}.png`} alt={'Type de l\'item'}/>
                </div> 
                )
            } catch (error) {
            console.log(`ItemSelected.js | image manquante pour ItemSelected ${itemSelected[id]}`)
            console.log(error)
        }
    }
}

export default ItemSelected