export const LEVEL_MAX = 230;

export const RARITY = {
	1: "Inhabituelle",
	2: "Rare",
	3: "Mythique",
	4: "Légendaire",
    5: "Souvenir",
    6: "Epique",
    7: "Relique",
}
export const TYPES = {
	101: "Hache (Deux mains)",
	103: "Anneau",
	108: "Baguette (Une main)",
	110: "Epée (Une main)",
    111: "Pelle (Deux mains)",
    112: "Dague (Seconde main)",
    113: "Bâton (Une main)",
    114: "Marteau (Deux mains)",
    115 : "Aiguille (Une main)", 
    117 : "Arc (Deux mains)", 
    119 : "Bottes", 
    120 : "Amulette", 
    132 : "Cape", 
    133 : "Ceinture", 
    134 : "Casque", 
    136 : "Plastron", 
    138 : "Epaulettes", 
    189 : "Bouclier (Seconde main)", 
    219 : "Poing", 
    223 : "Epée (Deux mains)", 
    253 : "Bâton (Deux mains)", 
    254 : "Cartes (Une main)", 
    480 : "Torches", 
    518 : "Armes 1 Main", 
    519 : "Armes 2 Mains", 
    520 : "Seconde Main", 
    537 : "Outils", 
    582 : "Familiers", 
    611 : "Montures", 
    646 : "Emblème", 
    647 : "Costumes",
}