import React from 'react'

import { useState } from 'react'

import '../styles/SelectType.css'

import { TYPES } from './Settings'

//let idType = [101, 103, 108, 110, 111, 112, 113, 114, 115, 117, 119, 120, 132, 133, 134, 136, 138, 189, 219, 223, 253, 254, 480, 518, 519, 520, 537, 582, 611, 646, 647];

//Liste des typeId utiliser pour afficher les buttons
let idTypeImage = [103, 112, 119, 120, 132, 133, 134, 136, 138, 189, 518, 519, 537, 582, 611, 646, 647];

//Gestion input button
//Si le typeId "id" est inclus dans "filter.typeId[]" alors l'exclu 
//Si non l'inclus
function inverseInput(filter, typeId, state, resetState) {
    var imageType = document.getElementById(typeId).className;
    typeId = Number(typeId);
    if (imageType === 'imageTypeDeselected')
    {
        filter.typeId.push(typeId);
    }
    else
    {
        for (var type in filter.typeId)
        {
            if (filter.typeId[type] === typeId)
            {
                filter.typeId[type] = filter.typeId[filter.typeId.length-1]
                filter.typeId.pop();
            }
        }
    }
    if (state === true)
    {
        return (resetState(false))
    }
    else
    {
        return (resetState(true))
    }
}
//Affiche les buttons
//Si le typeId "id" est inclus dans "filter.typeId[]" affiche un button de className 'Selected' 'Deselected'
function imageType(filter, state, resetState, id)
{   
    for (var type in filter.typeId)
    {
        if (filter.typeId[type] === id)
        {
            return(
            <div className='TypeSelection' key={id+'d_s'}>
                <button className='TypeSelected' key={id+'b_s'} onClick={() => inverseInput(filter, id, state, resetState)}>
                    <img className='imageTypeSelected' id={id} key={id+'i_s'} src={`./assets/type/${id}.png`} alt={`Icones du type ${TYPES[id]}`}/>
                </button>
                <span className="tooltiptext">{TYPES[id]}</span>
            </div>
            )
        
        }
    }
    return(
    <div className='TypeSelection' key={id+'d_d'}>
        <button className='TypeDeselected' key={id+'b_d'} onClick={() => inverseInput(filter, id, state, resetState)}>
            <img className='imageTypeDeselected' id={id} key={id+'i_d'} src={`../assets/type/${id}.png`} alt={`Icones du type ${TYPES[id]}`}/>
        </button>
        <span className="tooltiptext">{TYPES[id]}</span>
    </div>
    )
}

function SelectType({ copyFilter }) {
    const [state, resetState] = useState(true) //Permet d'accutualiser le components à chaque input
    return (
        <div className='SelectType'>
            {idTypeImage.map((id) => (imageType(copyFilter, state, resetState, id)))}
        </div>
        )
}

export default SelectType

/*
function setType(filter) {
    var val = document.getElementById("inputType").value
    for(var id in idType)
    {
        if (TYPES[idType[id]] === val)
        {
            filter.typeId[0] = idType[id];
            return ;
        }
    }
}*/

/*
<span>Catégorie d'objet:</span>
<input list="types" id="inputType" onBlur={() => setType(filter)}/>
<datalist id="types">
    {idType.map((id) => (<option value={TYPES[id]} key={id}/>))}
</datalist>*/