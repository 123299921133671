import '../styles/Menu.css'

function menuButton(stateApp, id, id2)
{
    
    var button = document.getElementById(id);
    button.className = 'Menu_Button_Selected';

    var button2 = document.getElementById(id2);
    button2.className = 'Menu_Button';

    if (id === 'Equipment_Button')
    {
        stateApp.menu = 0;
    }
    else
    {
        stateApp.menu = 1;
    }
    stateApp.update();
}

function Menu({ stateApp }) {
    return (
        <div className='Menu'>
            <button className='Menu_Button' id='Equipment_Button' onClick={() => menuButton(stateApp, 'Equipment_Button', 'Builds_Button')}>
                <span className='Menu_Text'>Équipements</span>
            </button>
            <button className='Menu_Button' id='Builds_Button' onClick={() => menuButton(stateApp, 'Builds_Button', 'Equipment_Button')}>
                <span className='Menu_Text'>Listes des builds</span>
            </button>
        </div>
    )
}

export default Menu