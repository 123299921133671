import '../styles/ItemsTabView.css'

import { useState, useEffect } from 'react'

import ItemTabView from './ItemTabView'

let ItemOutputVide = [];

//Test la rarity de l'objet pour le filtre
function testRarity(nbrItem, filter, jsonItems)
{
    if (filter.rarity.length === 0)
    {
        return (true);
    }

    for (var id in filter.rarity)
    {
        if (jsonItems[nbrItem].definition.item.baseParameters.rarity === filter.rarity[id])
        {
            return (true);
        }
    }
    
    return (false)
}
//Test le niveau de l'objet pour le filtre
function testLevel(nbrItem, filter, jsonItems)
{
    return (jsonItems[nbrItem].definition.item.level <= filter.levelMax && jsonItems[nbrItem].definition.item.level >= filter.levelMin)
}
//Test le type de l'objet pour le filtre
function testType(nbrItem, filter, jsonItems)
{    
    var typeItem = jsonItems[nbrItem].definition.item.baseParameters.itemTypeId
    if (typeItem === 812 || typeItem === 811 || typeItem === 480) //Sublimation //maitrise// torche
    {
        return (false);
    }
    if (filter.typeId.length === 0)//Si le filtre est vide
    {
        return (true);
    }
    for (var typeId in filter.typeId)
    {
        if (filter.typeId[typeId] === typeItem)
        {
            return (true);
        }
        else if (filter.typeId[typeId] === 518)//518 : "Armes 1 Main"
        {
            if (typeItem === 108 || typeItem === 110 || typeItem=== 113 || typeItem === 115 || typeItem === 219 || typeItem === 254)
            {
                return (true);
            }
        }
        else if (filter.typeId[typeId] === 519)//519 : "Armes 2 Mains"
        {
            if (typeItem === 101 || typeItem === 111 || typeItem === 114 || typeItem === 117 || typeItem === 223 || typeItem === 253)
            {
                return (true);
            }
        }
        else if (filter.typeId[typeId] === 520)//520 : "Seconde Main"
        {
            if (typeItem === 112 || typeItem === 189)
            {
                return (true);
            }
        }
    }
    return (false);
}
//Regroupe les tests pour le filtre
function testFilterItem(nbrItem, filter, jsonItems)
{
    if (nbrItem === 1467 || nbrItem === 512 || nbrItem === 6677 || nbrItem === 6675 || nbrItem === 6613)//Object sans effect
    {
        return (false);
    }

    if (testLevel(nbrItem, filter, jsonItems) && testType(nbrItem, filter, jsonItems) && testRarity(nbrItem, filter, jsonItems))
    {
        return (true);
    }
    else
    {
        return (false);
    } 
}
//Trie les items afficher
function sortItemOutput(ItemOutput, jsonItems, filter, setItemOutput)
{
    let ItemOutputSortLevel = [];
    var i;
    var item;
    
    if (filter.levelSort === 0)
    {
        for (i = filter.levelMin; i <= filter.levelMax; i++)
        {
            for (item in ItemOutput)
            {
                if (jsonItems[ItemOutput[item]].definition.item.level === i)
                {
                    ItemOutputSortLevel.push(ItemOutput[item]);
                }
            }
        }
    }
    else
    {
        for (i = filter.levelMax; i >= filter.levelMin; i--)
        {
            for (item in ItemOutput)
            {
                if (jsonItems[ItemOutput[item]].definition.item.level === i)
                {
                    ItemOutputSortLevel.push(ItemOutput[item]);
                }
            }
        }
    }
    setItemOutput(ItemOutputSortLevel);
}
//Si testFilterItem() === true alors ajouete l'item dans la liste a afficher ItemOutput[]
function pushItemOutput(index, filter, ItemOutput, jsonItems)
{
    if (testFilterItem(index, filter, jsonItems))
    {
        ItemOutput.push(index);
    }
}
//Afficher les items inclus dans la page actuel via <ItemTabView/>
function displayPage(stateApp, index, itemsSelected, stats, ItemOutput, page, jsonItems)
{   
    if(index >= 50 * page && index < 50 * page + 50)
    {
        return(
            <ItemTabView stateApp={stateApp} key={index} nbrItem={ItemOutput[index]} itemsSelected={itemsSelected} stats={stats} jsonItems={jsonItems}/>
        )
    }
}
//Affiche les buttons pour changer de pages
function pageButtons(page, setPage, nbrPage)
{
    if (page === 0 && page === nbrPage - 1)
    {
        return(
            <div className='ButtonPage_ItemTabView'>
            </div>
        )
    }
    else if (page === 0)
    {
        return(
            <div className='ButtonPage_ItemTabView'>
                <button style={{ visibility: 'hidden' }} className='ButtonArrowDown_ItemTabView' onClick={() => setPage(page - 1)}>
                </button>
                <div className='Page_ItemTabView'>
                    <span>{page+1}/{nbrPage}</span>
                </div>
                <button className='ButtonArrowUp_ItemTabView' onClick={() => setPage(page + 1)}>
                    <div className='ArrowUp_ItemTabView'></div>
                </button>
            </div>
        )
    }
    else if (page === nbrPage - 1)
    {
        return(
            <div className='ButtonPage_ItemTabView'>
                <button className='ButtonArrowDown_ItemTabView' onClick={() => setPage(page - 1)}>
                    <div className='ArrowDown_ItemTabView'></div>
                </button>
                <div className='Page_ItemTabView'>
                    <span>{page+1}/{nbrPage}</span>
                </div>
                <button style={{ visibility: 'hidden' }} className='ButtonArrowUp_ItemTabView' onClick={() => setPage(page + 1)}>
                </button>
            </div>
        )
    }
    else
    {
        return(
            <div className='ButtonPage_ItemTabView'>
                <button className='ButtonArrowDown_ItemTabView' onClick={() => setPage(page - 1)}>
                    <div className='ArrowDown_ItemTabView'></div>
                </button>
                <div className='Page_ItemTabView'>
                    <span>{page+1}/{nbrPage}</span>
                </div>
                <button className='ButtonArrowUp_ItemTabView' onClick={() => setPage(page + 1)}>
                    <div className='ArrowUp_ItemTabView'></div>
                </button>
            </div>
        )
    }
}
 
function ItemsTabView({ stateApp, filter, builds, stats ,jsonItems}) {
    const [page, setPage] = useState(0)
    const [ItemOutput, setItemOutput] = useState(ItemOutputVide)
    //Si le filter à été modififer OU //Si le fichier jsonItems à charger (une seul fois au début)
    useEffect(() => {
        setPage(0);
        if (jsonItems)
        {
            let ItemOutputToSort = [];
            jsonItems.map((item, index) => pushItemOutput(index, filter, ItemOutputToSort, jsonItems))
            sortItemOutput(ItemOutputToSort, jsonItems, filter, setItemOutput)
        }
    }, [filter, jsonItems])

    if (jsonItems)
    {
        return (
        <div className='ItemsTabView'>
            <div className='ItemsTabViewPage'>                
                {ItemOutput.map((item, index) => displayPage(stateApp, index, builds[stateApp.buildSelected], stats, ItemOutput, page, jsonItems))}
            </div>
            {pageButtons(page, setPage, Math.ceil(ItemOutput.length/50))}
        </div>
        )
    }
    else
    {
        return (
        <div className='ItemsTabView'>
            <div className='ItemsTabViewPage'>
            </div>
        </div>
        )
    }
}

export default ItemsTabView