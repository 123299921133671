import '../styles/ItemTabView.css'

import JSON_Types from '../datas/equipmentItemTypes.json'

import StatLine from './StatLine'

import { RARITY, TYPES } from './Settings'

//Equipe un item
function clickItemSelected(stateApp, nbrItem, itemsSelected, jsonItems)
{
    var typeItem = jsonItems[nbrItem].definition.item.baseParameters.itemTypeId
    let PossiblePositions;
    let DisabledPositions;

    for (var type in JSON_Types)
    {
        if (JSON_Types[type].definition.id === typeItem)
        {
            PossiblePositions = JSON_Types[type].definition.equipmentPositions;
            DisabledPositions = JSON_Types[type].definition.equipmentDisabledPositions;
        }
    }

    switch (PossiblePositions[0])
    {
        case 'FIRST_WEAPON':
            itemsSelected[0] = nbrItem;
            if (DisabledPositions[0])
            {
                itemsSelected[1] = -1;
            }
          break;
        case 'SECOND_WEAPON':
            itemsSelected[1] = nbrItem;
            if (itemsSelected[0] >= 0)
            {
                var typeFirstWeapon = jsonItems[itemsSelected[0]].definition.item.baseParameters.itemTypeId
                if (typeFirstWeapon === 101 || typeFirstWeapon === 111 || typeFirstWeapon === 114 || typeFirstWeapon === 117 || typeFirstWeapon === 223 || typeFirstWeapon === 253)
                {
                    itemsSelected[0] = -1;
                }
            }
          break;
        case 'LEFT_HAND':
            itemsSelected[2 + itemsSelected[14]] = nbrItem;
          break;
        case 'LEGS':
            itemsSelected[4] = nbrItem;
          break;
        case 'NECK':
            itemsSelected[5] = nbrItem;
          break;
        case 'BACK':
            itemsSelected[6] = nbrItem;
          break;
        case 'BELT':
            itemsSelected[7] = nbrItem;
          break;
          case 'HEAD':
            itemsSelected[8] = nbrItem;
          break;
        case 'CHEST':
            itemsSelected[9] = nbrItem;
          break;
        case 'SHOULDERS':
            itemsSelected[10] = nbrItem;
          break;
          case 'ACCESSORY':
            itemsSelected[11] = nbrItem;
          break;
        case 'PET':
            itemsSelected[12] = nbrItem;
          break;
        case 'COSTUME':
            itemsSelected[13] = nbrItem;
          break;
        default:
          console.log(`ItemTabView.js | Erreur possible positions ${PossiblePositions[0]}.`);
    }

    stateApp.update();
}
//Hover un boutton equiper l'objet
function mouseItemSelected(nbrItem, itemsSelected, jsonItems, hover)
{
    var id = 0;
    var disableId = -1;
    var typeItem = jsonItems[nbrItem].definition.item.baseParameters.itemTypeId
    let PossiblePositions;
    let DisabledPositions;
    var slotItem;

    if (hover === false)
    {
        for (var i = 0; i <= 13; i++)
        {
            slotItem = document.getElementById(`ItemSelected_${i}`);
            slotItem.className = "ItemSelected";
        }
        return;
    }

    for (var type in JSON_Types)
    {
        if (JSON_Types[type].definition.id === typeItem)
        {
            PossiblePositions = JSON_Types[type].definition.equipmentPositions;
            DisabledPositions = JSON_Types[type].definition.equipmentDisabledPositions;
        }
    }

    switch (PossiblePositions[0]) 
    {
        case 'FIRST_WEAPON':
            if (DisabledPositions[0])
            {
                disableId = 1;
            }
          break;
        case 'SECOND_WEAPON':
            id = 1;
            if (itemsSelected[0] >= 0)
            {
                var typeFirstWeapon = jsonItems[itemsSelected[0]].definition.item.baseParameters.itemTypeId
                if (typeFirstWeapon === 101 || typeFirstWeapon === 111 || typeFirstWeapon === 114 || typeFirstWeapon === 117 || typeFirstWeapon === 223 || typeFirstWeapon === 253)
                {
                    disableId = 0;
                }
            }
          break;
        case 'LEFT_HAND':
            id = 2 + itemsSelected[14];
          break;
          case 'LEGS':
            id = 4;
          break;
        case 'NECK':
            id = 5;
          break;
        case 'BACK':
            id = 6;
          break;
        case 'BELT':
            id = 7;
          break;
          case 'HEAD':
            id = 8;
          break;
        case 'CHEST':
            id = 9;
          break;
        case 'SHOULDERS':
            id = 10;
          break;
          case 'ACCESSORY':
            id = 11;
          break;
        case 'PET':
            id = 12;
          break;
        case 'COSTUME':
            id = 13;
          break;
        default:
            console.log(`ItemTabView.js | Erreur possible positions ${PossiblePositions[0]}.`);
            return;
    }
    slotItem = document.getElementById(`ItemSelected_${id}`);
    if (hover === true)
    {
        slotItem.className = "ItemSelected greenOutLine";
        if (disableId >= 0)
        {
            var slotDisableItem = document.getElementById(`ItemSelected_${disableId}`);
            slotDisableItem.className = "ItemSelected redOutLine";
        }
    }
}
//Affiche chaque effect pour un item, un par ligne
function displayStatItem(nbrItem, stats, itemsSelected, jsonItems)
{
    return(
    jsonItems[nbrItem].definition.equipEffects.map((equipEffect, index) => 
            <StatLine key={index} nbrItem={nbrItem} effect={equipEffect.effect} stats={stats} itemsSelected={itemsSelected} jsonItems={jsonItems}/>)
    )
}
//Affiche l'image de l'item
function imageItem(nbrItem, jsonItems)
{
    try {
        return(
            <img className='ImageItem_ItemTabView' src={`../assets/images_items/${jsonItems[nbrItem].definition.item.graphicParameters}.png`} alt={'Image de l\'item : ' + jsonItems[nbrItem].title}/>    
        )
    } catch (error) {
        console.log(`ItemTabView.js | image manquante : ../assets/images_items/${jsonItems[nbrItem].definition.item.graphicParameters}.png`);
        console.log('ItemTabView.js | nbrItem : ', nbrItem);
        console.log('ItemTabView.js | item.id : ', jsonItems[nbrItem].definition.item.id);
        console.log(error);        
        
    }
}
//Affiche l'image du le type de l'item
function imageType(idType)
{
    if (idType === 108 || idType === 110 || idType=== 113 || idType === 115 || idType === 219 || idType === 254)
    {
        idType = 518;
    }
    if (idType === 101 || idType === 111 || idType === 114 || idType === 117 || idType === 223 || idType === 253)
    {
        idType = 519;
    }
    try {
        return(
            <img className='ImageType_ItemTabView' src={`../assets/type/${idType}.png`} alt={`Icones du type ${TYPES[idType]}`}/>
        )
    } catch (error) {
        console.log(`ItemTabView.js | image manquante : ../assets/type/${idType}.png`);
        console.log('ItemTabView.js | idType : ', idType);
        console.log(error);        
        
    }
}
//Change le focus (itemsSelected[14]) entre la main gauche/droite, pour équiper les anneaux
function clickSwapRing(stateApp, itemsSelected)
{
    if (itemsSelected[14] === 1)
    {
        itemsSelected[14] = 0;
    }
    else
    {
        itemsSelected[14] = 1;
    }
    stateApp.update();
}
//Affiche le boutton pour changer le focus (itemsSelected[14]) entre la main gauche/droite, pour équiper les anneaux
function displaySwapRing(stateApp, itemsSelected, nbrItem, jsonItems)
{
    mouseItemSelected(nbrItem, itemsSelected, jsonItems, false);
    if (itemsSelected[14] === 1)
    {
        return(
            <div className='TooltipDiv_ItemTabView'>
                <div className='Type_ItemTabView' onClick={() => clickSwapRing(stateApp, itemsSelected)} onMouseOver={() => mouseItemSelected(nbrItem, itemsSelected, jsonItems, true)} onMouseOut={() => mouseItemSelected(nbrItem, itemsSelected, jsonItems, false)}>
                    {imageType(1)}
                </div>
                <span className="Tooltip_ItemTabView">Focus sur l'anneau gauche</span>
            </div>
        )
    }
    else
    {
        return(
            <div className='TooltipDiv_ItemTabView'>
                <div className='Type_ItemTabView' onClick={() => clickSwapRing(stateApp, itemsSelected)} onMouseOver={() => mouseItemSelected(nbrItem, itemsSelected, jsonItems, true)} onMouseOut={() => mouseItemSelected(nbrItem, itemsSelected, jsonItems, false)}>
                    {imageType(0)}
                </div>
                <span className="Tooltip_ItemTabView">Focus sur l'anneau droit</span>
            </div>
        )
    }
    
}
function ItemTabView({ stateApp, nbrItem, itemsSelected, stats, jsonItems}) 
{
    var idType = jsonItems[nbrItem].definition.item.baseParameters.itemTypeId
    var idItem = jsonItems[nbrItem].definition.item.id
       
    return (
        <div className='ItemTabView'>
            <div className='ImageButton_ItemTabView'>
                {imageItem(nbrItem, jsonItems)}
                <div className='ButtonBelowImageItem_ItemTabView'>
                    <div className='TooltipDiv_ItemTabView'>
                        <a className="Link_ItemTabView" href={`https://www.wakfu.com/fr/mmorpg/encyclopedie/armures/${idItem}`} target="_blank" rel="noreferrer">
                            <img className="ImageLink_ItemTabView" src={`../assets/logo_wakfu/wakfu.png`} alt={`Lien vers Encyclopédie site officiel`}/>
                        </a>
                        <span className="Tooltip_ItemTabView">Lien Wiki Officiel</span>
                    </div>
                    <div className='TooltipDiv_ItemTabView'>
                        <div className='Type_ItemTabView' onClick={() => clickItemSelected(stateApp, nbrItem, itemsSelected, jsonItems)} onMouseOver={() => mouseItemSelected(nbrItem, itemsSelected, jsonItems, true)} onMouseOut={() => mouseItemSelected(nbrItem, itemsSelected, jsonItems, false)}>
                            {imageType(idType)}
                        </div>
                        <span className="Tooltip_ItemTabView">{`Équiper l'objet`}</span>
                    </div>
                    {(103 === idType) ? displaySwapRing(stateApp, itemsSelected, nbrItem, jsonItems): ''}
                </div>
            </div>
            <div className='ItemStatView'>
                <span>{jsonItems[nbrItem].title}</span>
                <span>Niv. {jsonItems[nbrItem].definition.item.level}</span>
                <div className='IconAndName'>
                <img src={`../assets/icones/${jsonItems[nbrItem].definition.item.baseParameters.rarity}.png`} alt={'Icones de la rareté'}/>
                <span>{RARITY[jsonItems[nbrItem].definition.item.baseParameters.rarity]}</span>
                </div>
                {displayStatItem(nbrItem, stats, itemsSelected, jsonItems)}
            </div>
        </div>
    )
        
}
/* Lancer script PHP pour télécharger les images manquantes
if (window.XMLHttpRequest){
var xmlhttp=new XMLHttpRequest();

var PageToSendTo = "script.php?";
var MyVariable = `${jsonItems[nbrItem].definition.item.graphicParameters.gfxId}.png`;
var VariablePlaceholder = "var=";
var UrlToSend = PageToSendTo + VariablePlaceholder + MyVariable;

xmlhttp.open("GET", UrlToSend, false);
xmlhttp.send();*/

export default ItemTabView
