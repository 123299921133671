import '../styles/App.css'

import ItemsTabView from './ItemsTabView'
import ItemsFilter from './ItemsFilter'
import Footer from './Footer'
import Header from './Header'
import StatsView from './StatsView'
import Menu from './Menu';
import ItemsSelected from './ItemsSelected'

import { useState, useEffect } from 'react'

var i = 0;


let filterObject = {
    levelMin: 5,
    levelMax: 50,
    levelSort: 1,
    typeId: [],
    rarity: [],
  };
let itemsSelectedObject = {
    0: -1, //FIRST_WEAPON
    1: -1, //SECOND_WEAPON
    2: -1, //LEFT_HAND
    3: -1, //RIGHT_HAND
    4: -1, //LEGS
    5: -1, //NECK
    6: -1, //BACK
    7: -1, //BELT
    8: -1, //HEAD
    9: -1, //CHEST
    10: -1, //SHOULDERS
    11: -1, //ACCESSORY
    12: -1, //PET
    13: -1, //COSTUME
    14: 0,//0=LEFT_HAND 1=RIGHT_HAND
};
let builds = [itemsSelectedObject, itemsSelectedObject, itemsSelectedObject];
let stateApp = {
    app: {},
    setApp: {},
    menu: 0,
    buildSelected: 0,
    update : function() {
        if (this.app === true) {
            this.setApp(false)
        }
        else {
            this.setApp(true)
        }
    }
};
let stats = {
    PA: 6,
    PM: 3,
    PdV: 100,
    PW: 6,
    MasteryElemental: 0,
    ResistanceElemental: 0,
    AquaDamage: 0,
    AquaResistance: 0,
    EarthDamage: 0,
    EarthResistance: 0,
    WindDamage: 0,
    WindResistance: 0,
    FireDamage: 0,
    FireResistance: 0,
    Damage: 0,
    Heal: 0,
    Critical: 0,
    Parade: 0,
    Initiative: 0,
    Range: 0,
    Dodge: 0,
    Tackle: 0,
    Wisdom: 0,
    Prospecting: 0,
    Invocation: 0,
    Will: 0, 
    MasteryCritical: 0,
    ResistanceCritical: 0,
    MasteryBack: 0,
    ResistanceBack: 0,
    MasteryMelee: 0,
    MasteryDistance: 0,
    MasteryMono: 0,
    MasteryMulti: 0,
    MasteryHeal: 0,
    MasteryBerserk: 0,
    ArmorOut: 0,
    ArmorIn: 0,
};

function resetStat(stats)
{
    stats = {
        PA: 6,
        PM: 3,
        PdV: 100,
        PW: 6,
        MasteryElemental: 0,
        ResistanceElemental: 0,
        AquaDamage: 0,
        AquaResistance: 0,
        EarthDamage: 0,
        EarthResistance: 0,
        WindDamage: 0,
        WindResistance: 0,
        FireDamage: 0,
        FireResistance: 0,
        Damage: 0,
        Heal: 0,
        Critical: 0,
        Parade: 0,
        Initiative: 0,
        Range: 0,
        Dodge: 0,
        Tackle: 0,
        Wisdom: 0,
        Prospecting: 0,
        Invocation: 0,
        Will: 0, 
        MasteryCritical: 0,
        ResistanceCritical: 0,
        MasteryBack: 0,
        ResistanceBack: 0,
        MasteryMelee: 0,
        MasteryDistance: 0,
        MasteryMono: 0,
        MasteryMulti: 0,
        MasteryHeal: 0,
        MasteryBerserk: 0,
        ArmorOut: 0,
        ArmorIn: 0,
      };
    return (stats);
}

function addStatItem(item, stats, jsonItems)
{
    //Pour chaque effet de l'item
    for(var effect in jsonItems[item].definition.equipEffects)
    {
        switch (jsonItems[item].definition.equipEffects[effect].effect.definition.actionId) 
        {
            case 20:
                stats.PdV += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 21:
                stats.PdV -= jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 26:
                stats.MasteryHeal += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 31:
                stats.PA += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 39:
                stats.ArmorIn += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 41:
                stats.PM += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 56: //"-1 PA Max" actuelement gére juste comme "-1 PA" //14Pa/8Pm max  //INFORMATION A VERIFIER NON FIABLE
                stats.PA -= jsonItems[item].definition.equipEffects[effect].effect.definition.params[0]; 
                break;
            case 57: //"-1 PM Max" actuelement gére juste comme "-1 PM" //14Pa/8Pm max  //INFORMATION A VERIFIER NON FIABLE
                stats.PM -= jsonItems[item].definition.equipEffects[effect].effect.definition.params[0]; 
                break;
            case 71:
                stats.ResistanceBack += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 80:
                stats.ResistanceElemental += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 82:
                stats.FireResistance += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 83:
                stats.AquaResistance += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 84:
                stats.EarthResistance += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 85:
                stats.WindResistance += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 90: //doublon ? Amulette L'avie
                stats.ResistanceElemental -= jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 100: //doublon ? Amulette L'avie
                stats.ResistanceElemental -= jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 120:
                stats.MasteryElemental += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 122:
                stats.FireDamage += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 123:
                stats.EarthDamage += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 124:
                stats.AquaDamage += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 125:
                stats.WindDamage += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 130:
                stats.MasteryElemental -= jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 149:
                stats.MasteryCritical += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;  
            case 150:
                stats.Critical += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 160:
                stats.Range += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 161:
                stats.Range -= jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 162:
                stats.Prospecting += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 166:
                stats.Wisdom += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;     
            case 168:
                stats.Critical -= jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;                   
            case 171:
                stats.Initiative += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;  
            case 173:
                stats.Tackle += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 174:
                stats.Tackle -= jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break; 
            case 175:
                stats.Dodge += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;   
            case 176:
                stats.Dodge -= jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;  
            case 177:
                stats.Will += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;  
            case 180:
                stats.MasteryBack += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 181:
                stats.MasteryBack -= jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 184:
                stats.Invocation += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 191:
                stats.PW += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break; 
            case 192: //"-1 PW Max" actuelement gére juste comme "-1 PW" //14Pa/8Pm max  //INFORMATION A VERIFIER NON FIABLE
                stats.PW -= jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break; 
            case 875:
                stats.Parade += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;  
            case 988:
                stats.ResistanceCritical += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;  
            case 1050:
                stats.MasteryMulti += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 1051:
                stats.MasteryMono += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;       
            case 1052:
                stats.MasteryMelee += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 1053:
                stats.MasteryDistance += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;  
            case 1055:
                stats.MasteryBerserk += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 1061:
                stats.MasteryBerserk -= jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 1062:
                stats.ResistanceCritical -= jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 1063:
                stats.ResistanceBack -= jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 1068: //Mastery Elemental sur X éléments aléaroires
                stats.MasteryElemental += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 1069: //Resistance Elemental sur X éléments aléaroires
                stats.ResistanceElemental += jsonItems[item].definition.equipEffects[effect].effect.definition.params[0];
                break;
            case 304: //Effet spécial, non pris en compte
                break;
            case 832: //Niveau au sort, non pris en compte
                break; 
            case 1020: //Renvoi de damage, non pris en compte
                break;
            case 2001: //Taux récolte métier, non pris en compte
                break;
            case 234: //Art du barda (disparaît bientôt !), non pris en compte
                break;
            default:
                console.log(`App.js | Effect non reconnu ni géré actionId : ${jsonItems[item].definition.equipEffects[effect].effect.definition.actionId}.`);
                console.log(`App.js | Effect non reconnu ni géré value : ${jsonItems[item].definition.equipEffects[effect].effect.definition.params[0]}.`);
        }
    } 
    return (stats);
}
                
function caculStat(stats, itemsSelected, jsonItems) {
    for(var item in itemsSelected)
    { 
        if(itemsSelected[item] >= 0 && item < 14)
        {
            stats = addStatItem(itemsSelected[item], stats, jsonItems);
        }
    }
    stats.AquaDamage += stats.MasteryElemental;
    stats.FireDamage += stats.MasteryElemental;
    stats.WindDamage += stats.MasteryElemental;
    stats.EarthDamage += stats.MasteryElemental;
    stats.AquaResistance += stats.ResistanceElemental;
    stats.FireResistance += stats.ResistanceElemental;
    stats.WindResistance += stats.ResistanceElemental;
    stats.EarthResistance += stats.ResistanceElemental;
    return (stats);
}

function setJson(setJsonItems)
{
    fetch("/JSON/items_opti.json")
        .then(function(res) {
            if (res.ok) {
            return res.json();
            }
        })
        .then(function(value) {
            setJsonItems(value);
        })
        .catch(function(err) {
            console.log(err);
            // Une erreur est survenue
        });
}

function downloadFiles(data, file_name, file_type) {
    var file = new Blob([data], {type: file_type});
    if (window.navigator.msSaveOrOpenBlob) 
        window.navigator.msSaveOrOpenBlob(file, file_name);
    else { 
        var a = document.createElement("a"),
                url = URL.createObjectURL(file);
        a.href = url;
        a.download = file_name;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);  
        }, 0); 
    }
}

function optiJson(jsonItems) 
{
    let jsonItemsOpti = [];
    
    for (var item in jsonItems)
    {
        if (jsonItems[item].definition.equipEffects.length === 0)
        {
            //delete jsonItems[item];
            //jsonItems.splice(item, 1); 
        }
        else
        {
            delete jsonItems[item].description;
            jsonItems[item].title = jsonItems[item].title.fr;
            jsonItems[item].definition.item.graphicParameters = jsonItems[item].definition.item.graphicParameters.gfxId;
            //console.log("jsonItems ", jsonItems[item]);
            jsonItemsOpti.push(jsonItems[item]);
            //console.log("jsonItemsOpti ", jsonItemsOpti[jsonItemsOpti.length-1]);
        }
    }
    downloadFiles(JSON.stringify(jsonItemsOpti), "items_opti", "json");
}

function loadBuild(builds, name)
{
    var requete = new XMLHttpRequest();
    requete.onload = function() 
    {
        var response = this.responseText;
        response = response.slice(0, response.length - 4);
        if (response !== '[]')
        {
            builds.push(JSON.parse(response)[0]);
        }        
    };
    requete.open("GET", "get_build.php?User=" + name, true);
    requete.send();
}

function selectBuild(stateApp, id)
{
    stateApp.buildSelected = id;
    stateApp.update();
}

function addBuild(stateApp, id, builds)
{
    let itemsSelected = Object.assign({}, itemsSelectedObject);
    builds.push(itemsSelected);

    stateApp.buildSelected = id;
    stateApp.update();
}

function suppressBuild(stateApp, id)
{
    //stateApp.buildSelected = id;
    //stateApp.update();
}

function displayItemsSelected(stateApp, lastIndex, index, itemsSelected, jsonItems)
{    
    if (index === stateApp.buildSelected)
    {
        return (
            <div className='Build_In_BuildsList'>
                <div className='Selected_Build'></div>
                <ItemsSelected itemsSelected={itemsSelected} jsonItems={jsonItems}/>
                <button className='Suppress_Build' onClick={() => suppressBuild(stateApp, index)}></button>
            </div>
        )
    }
    else
    {
        return (
            <div className='Build_In_BuildsList'>
                <button className='Set_Build' onClick={() => selectBuild(stateApp, index)}></button>
                <ItemsSelected itemsSelected={itemsSelected} jsonItems={jsonItems}/>
                <button className='Suppress_Build' onClick={() => suppressBuild(stateApp, index)}></button>
            </div>
        )
    }
}

function displayAddLastItemsSelected(stateApp, lastIndex, index, builds, jsonItems)
{
    if(index <= 12)
    {
        return (
            <div className='Build_In_BuildsList'>
                <button className='Add_Build' onClick={() => addBuild(stateApp, index, builds)}></button>
                <ItemsSelected itemsSelected={itemsSelectedObject} jsonItems={jsonItems}/>
            </div>
        )
    }
}

function CenterApp(stateApp, filter, setFilter, builds, stats, jsonItems)
{
    if (stateApp.menu === 0)
    {
        return (
            <>
                <Menu stateApp={stateApp}/>
                <StatsView stats={stats}/>
                <ItemsTabView stateApp={stateApp} filter={filter} builds={builds} stats={stats} jsonItems={jsonItems}/>
                <ItemsFilter stateApp={stateApp} filter={filter} setFilter={setFilter}/>
            </>
        )
    }
    else
    {
        return (
            <>
                <Menu stateApp={stateApp}/>
                <div className='Builds_List'>
                    {builds.map((itemsSelected, index) => displayItemsSelected(stateApp, builds.length - 1, index, itemsSelected, jsonItems))}
                    {displayAddLastItemsSelected(stateApp, builds.length - 1, builds.length, builds, jsonItems)}
                </div>                
            </>
        )
    }
}

function App() {
    const [filter, setFilter] = useState(filterObject)
    const [jsonItems, setJsonItems] = useState(0)
    const [name, setName] = useState('')
    const [app, setApp] = useState(true);
    stateApp.app = app; //Pour relancer App() : stateApp.update()

    //Permiere execution de App()
    useEffect(() => {
        setJson(setJsonItems);        
        stateApp.setApp = setApp;
    }, [])

    //Login changer
    useEffect(() => {
        if (name !== '') {
            loadBuild(builds, name)
        }
    }, [name])

    if(jsonItems !== 0 && i === 0)
    {
        i = 1;
        //optiJson(jsonItems);
    }
    stats = resetStat(stats);
    stats = caculStat(stats, builds[0], jsonItems);
    return (
        <div className='App'>
            <Header stateApp={stateApp} builds={builds} name={name} setName={setName} jsonItems={jsonItems}/>
            <div className='CenterApp'>
                {CenterApp(stateApp, filter, setFilter, builds, stats, jsonItems)}
            </div>
            <Footer />
        </div>
        )
}

export default App