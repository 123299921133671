import '../styles/StatsView.css'

function StatsView({ stats }) {

    return (
        <div className='StatsView'>
            <div className='PrimalStats'>
                <div className='Stat'>
                    <div className='IconAndName'>
                        <img className='icon' id='icon_pdv' src={`../assets/icones/PdV.png`} alt={'Icone PdV'}/>
                        <span>PdV</span>
                    </div>
                    <span>{stats.PdV}</span>
                </div>
                <div className='Stat'> 
                    <div className='IconAndName'>
                        <img className='icon' id='icon_PA' src={`../assets/icones/PA.png`} alt={'Icone PA'}/> 
                        <span>PA</span>
                    </div>
                    <span>{stats.PA}</span>
                </div>
                <div className='Stat'> 
                    <div className='IconAndName'>
                        <img className='icon' id='icon_PW' src={`../assets/icones/PW.png`} alt={'Icone PW'}/> 
                        <span>PW</span>
                    </div>
                    <span>{stats.PW}</span>
                </div>
                <div className='Stat'>
                    <div className='IconAndName'>
                        <img className='icon' id='icon_PM' src={`../assets/icones/PM.png`} alt={'Icone PM'}/> 
                        <span>PM</span>
                    </div>
                    <span>{stats.PM}</span>
                </div>
            </div>
            <div className='ElementalStats'>
                <div className='Stat'>
                    <div className='Stat_aqua_damage'>
                        <img className='icon' id='icon_aqua_damage' src={`../assets/icones/aqua_damage.png`} alt={'Icone dommage eau'}/> 
                        <span>{stats.AquaDamage}</span>
                    </div>
                    <div className='Stat_aqua_resistance'>
                        <img className='icon' id='icon_aqua_resistance' src={`../assets/icones/aqua_resistance.png`} alt={'Icone résistance eau'}/> 
                        <span>{stats.AquaResistance}</span>
                    </div>
                </div>
                <div className='Stat'> 
                    <div className='Stat_earth_damage'>
                        <img className='icon' id='icon_earth_damage' src={`../assets/icones/earth_damage.png`} alt={'Icone dommage terre'}/> 
                        <span>{stats.EarthDamage}</span>
                    </div>
                    <div className='Stat_earth_resistance'>
                        <img className='icon' id='iconearth_resistance' src={`../assets/icones/earth_resistance.png`} alt={'Icone résistance terre'}/> 
                        <span>{stats.EarthResistance}</span>
                    </div>
                </div>
                <div className='Stat'>
                    <div className='Stat_wind_damage'>
                        <img className='icon' id='icon_wind_damage' src={`../assets/icones/wind_damage.png`} alt={'Icone dommage air'}/> 
                        <span>{stats.WindDamage}</span>
                    </div>
                    <div className='Stat_wind_resistance'>
                        <img className='icon' id='icon_wind_resistance' src={`../assets/icones/wind_resistance.png`} alt={'Icone résistance air'}/> 
                        <span>{stats.WindResistance}</span>
                    </div>
                </div>
                <div className='Stat'> 
                    <div className='Stat_fire_damage'>
                        <img className='icon' id='icon_fire_damage' src={`../assets/icones/fire_damage.png`} alt={'Icone dommage feu'}/> 
                        <span>{stats.FireDamage}</span>
                    </div>
                    <div className='Stat_fire_resistance'>
                        <img className='icon' id='icon_fire_resistance' src={`../assets/icones/fire_resistance.png`} alt={'Icone résistance feu'}/> 
                        <span>{stats.FireResistance}</span>
                    </div>
                </div>
            </div>
            <div className='SecondaryStats'>
                <div className='Stat'>
                    <div className='IconAndName'>
                        <img className='icon' id='icon_damage' src={`../assets/icones/damage.png`} alt={'Icone dammage'}/>
                        <span>Dommages infligés</span>
                    </div>
                    <span>{stats.Damage}</span>
                </div>
                <div className='Stat'>
                    <div className='IconAndName'>
                        <img className='icon' id='icon_heal' src={`../assets/icones/heal.png`} alt={'Icone soin'}/> 
                        <span>Soins réalisés</span>
                    </div>
                    <span>{stats.Heal}</span>
                </div>
                <div className='Stat'>
                    <div className='IconAndName'>
                        <img className='icon' id='icon_critical' src={`../assets/icones/critical.png`} alt={'Icone coup critique'}/> 
                        <span>Coup critique</span>
                    </div>
                    <span>{stats.Critical}</span>
                </div>
                <div className='Stat'>
                    <div className='IconAndName'>
                        <img className='icon' id='icon_parade' src={`../assets/icones/parade.png`} alt={'Icone parade'}/> 
                        <span>Parade</span>
                    </div>
                    <span>{stats.Parade}</span>
                </div>
                <div className='Stat'> 
                    <div className='IconAndName'>
                        <img className='icon' id='icon_initiative' src={`../assets/icones/initiative.png`} alt={'Icone initiative'}/>
                        <span>Initiative</span>
                    </div>
                    <span>{stats.Initiative}</span>
                </div>
                <div className='Stat'> 
                    <div className='IconAndName'>
                        <img className='icon' id='icon_range' src={`../assets/icones/range.png`} alt={'Icone portée'}/> 
                        <span>Portée</span>
                    </div>
                    <span>{stats.Range}</span>
                </div>
                <div className='Stat'>
                    <div className='IconAndName'>
                        <img className='icon' id='icon_dodge' src={`../assets/icones/dodge.png`} alt={'Icone esquive'}/> 
                        <span>Esquive</span>
                    </div>
                    <span>{stats.Dodge}</span>
                </div>
                <div className='Stat'> 
                    <div className='IconAndName'>
                        <img className='icon' id='icon_tackle' src={`../assets/icones/tackle.png`} alt={'Icone tacle'}/> 
                        <span>Tacle</span>
                    </div>
                    <span>{stats.Tackle}</span>
                </div>
                <div className='Stat'>
                    <div className='IconAndName'>
                        <img className='icon' id='icon_wisdom' src={`../assets/icones/wisdom.png`} alt={'Icone sagesse'}/>
                        <span>Sagesse</span>
                    </div>
                    <span>{stats.Wisdom}</span>
                </div>
                <div className='Stat'>
                    <div className='IconAndName'>
                        <img className='icon' id='icon_prospecting' src={`../assets/icones/prospecting.png`} alt={'Icone prospection'}/> 
                        <span>Prospection</span>
                    </div>
                    <span>{stats.Prospecting}</span>
                </div>
                <div className='Stat'> 
                    <div className='IconAndName'>
                        <img className='icon' id='icon_invocation' src={`../assets/icones/invocation.png`} alt={'Icone contrôle'}/> 
                        <span>Contrôle</span>
                    </div>
                    <span>{stats.Invocation}</span>
                </div>
                <div className='Stat'> 
                    <div className='IconAndName'>
                        <img className='icon' id='icon_will' src={`../assets/icones/will.png`} alt={'Icone volonté'}/> 
                        <span>Volonté</span>
                    </div>
                    <span>{stats.Will}</span>
                </div>
                <div className='Stat'>
                    <div className='IconAndName'>
                        <img className='icon' id='icon_mastery_critical' src={`../assets/icones/mastery_critical.png`} alt={'Icone maîtrise critique'}/>
                        <span>Maîtrise critique</span>
                    </div>
                    <span>{stats.MasteryCritical}</span>
                </div>
                <div className='Stat'>
                    <div className='IconAndName'>
                        <img className='icon' id='icon_resistance_critical' src={`../assets/icones/resistance_critical.png`} alt={'Icone résistance critique'}/> 
                        <span>Résistance critique</span>
                    </div>
                    <span>{stats.ResistanceCritical}</span>
                </div>
                <div className='Stat'>
                    <div className='IconAndName'>
                        <img className='icon' id='icon_mastery_back' src={`../assets/icones/mastery_back.png`} alt={'Icone maîtrise dos'}/> 
                        <span>Maîtrise dos</span>
                    </div>
                    <span>{stats.MasteryBack}</span>
                </div>
                <div className='Stat'>
                    <div className='IconAndName'>
                        <img className='icon' id='icon_resistance_back' src={`../assets/icones/resistance_back.png`} alt={'Icone résistance dos'}/> 
                        <span>Résistance dos</span>
                    </div>
                    <span>{stats.ResistanceBack}</span>
                </div>
                <div className='Stat'> 
                    <div className='IconAndName'>
                        <img className='icon' id='icon_mastery_melee' src={`../assets/icones/mastery_melee.png`} alt={'Icone maîtrise mêlée'}/>
                        <span>Maîtrise mêlée</span>
                    </div>
                    <span>{stats.MasteryMelee}</span>
                </div>
                <div className='Stat'> 
                    <div className='IconAndName'>
                        <img className='icon' id='icon_mastery_distance' src={`../assets/icones/mastery_distance.png`} alt={'Icone maîttrise distance'}/> 
                        <span>Maîtrise distance</span>
                    </div>
                    <span>{stats.MasteryDistance}</span>
                </div>
                <div className='Stat'>
                    <div className='IconAndName'>
                        <img className='icon' id='icon_mastery_mono' src={`../assets/icones/mastery_mono.png`} alt={'Icone maîtrise monocible'}/> 
                        <span>Maîtrise monocible</span>
                    </div>
                    <span>{stats.MasteryMono}</span>
                </div>
                <div className='Stat'> 
                    <div className='IconAndName'>
                        <img className='icon' id='icon_mastery_multi' src={`../assets/icones/mastery_multi.png`} alt={'Icone maîtrise zone'}/> 
                        <span>Maîtrise zone</span>
                    </div>
                    <span>{stats.MasteryMulti}</span>
                </div>
                <div className='Stat'>
                    <div className='IconAndName'>
                        <img className='icon' id='icon_mastery_heal' src={`../assets/icones/mastery_heal.png`} alt={'Icone maîtrise soin'}/>
                        <span>Maîtrise soin</span>
                    </div>
                    <span>{stats.MasteryHeal}</span>
                </div>
                <div className='Stat'>
                    <div className='IconAndName'>
                        <img className='icon' id='icon_mastery_berserk' src={`../assets/icones/mastery_berserk.png`} alt={'Icone maîtrise berserk'}/> 
                        <span>Maîtrise berserk</span>
                    </div>
                    <span>{stats.MasteryBerserk}</span>
                </div>
                <div className='Stat'> 
                    <div className='IconAndName'>
                        <img className='icon' id='icon_armor_out' src={`../assets/icones/armor_out.png`} alt={'Icone armure donnée'}/> 
                        <span>Armure donnée</span>
                    </div>
                    <span>{stats.ArmorOut}</span>
                </div>
                <div className='Stat'> 
                    <div className='IconAndName'>
                        <img className='icon' id='icon_armor_in' src={`../assets/icones/armor_in.png`} alt={'Icone armure reçue'}/> 
                        <span>Armure reçue</span>
                    </div>
                    <span>{stats.ArmorIn}</span>
                </div>
            </div>
        </div>
    )
}

export default StatsView