function PhpRegistration(name, email, password, setName, setTestRegistration)
{
    var requete = new XMLHttpRequest();
    requete.onload = function() 
    {
        var response = this.responseText;
        response = response.slice(0, response.length - 4);

        if (response === '1')
        {
            setName(name);
            document.cookie = 'user=' + name + '; path =/;samesite=strict; secure';
            return (1)
        }
        else
        {
            setTestRegistration(-1)
            return (0)
        }
    };
    requete.open("GET", "registration.php?Name=" + name + "&Email=" + email + "&Password=" + password, true);
    requete.send();
}
export default PhpRegistration
