import '../styles/ItemsFilter.css'

import { useState } from 'react'

import SelectType from './SelectType'
import SelectRarity from './SelectRarity'
import SelectSort from './SelectSort'
import MultiRangeSlider from './MultiRangeSlider'

//Recrée un objet filter pour update le state et pouvoir rerender()
function updateFilter(filter, setFilter) {
    let newFilter = Object.assign({}, filter);
    newFilter.typeId = [...filter.typeId];
    return setFilter(newFilter)
}

function updateFilterLevel(filter, levelMin, levelMax) {
    filter.levelMin = levelMin;
    filter.levelMax = levelMax;
}

function ItemsFilter({ filter, setFilter }) {
    const [levelMin, setLevelMin] = useState(5)
    const [levelMax, setLevelMax] = useState(50)//Settings.LEVEL_MAX)
    const [copyFilter, setCopyFilter] = useState(0)
    if(copyFilter === 0)//Permiere execution de ItemsFilter()
    {
        updateFilter(filter, setCopyFilter);
        return(<></>);
    }

    updateFilterLevel(copyFilter, levelMin, levelMax);
    return (
        <div className='ItemsFilter'>
            <h2>Niveau ({levelMin} - {levelMax}) :</h2>
            <MultiRangeSlider levelMin={levelMin} levelMax={levelMax} setLevelMin={setLevelMin} setLevelMax={setLevelMax}/>
            <h2>Rareté :</h2>
            <SelectRarity copyFilter={copyFilter}/>
            <h2>Type d'équipement :</h2>
            <SelectType copyFilter={copyFilter}/>
            <h2>Trier par :</h2>
            <SelectSort copyFilter={copyFilter}/>
            <div>
                <button className='Button_ItemsFilter' onClick={() => updateFilter(copyFilter, setFilter)}>Recherche</button>
            </div>
        </div>
    )
}

export default ItemsFilter